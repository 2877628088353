import classnames from 'classnames';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import { Box } from '@zenobius/ui-web-components-box';

import * as Styles from './Banner.css';

export function Banner({ src, credit }: { src: string; credit?: string }) {
  return (
    <Box
      style={assignInlineVars({
        [Styles.BannerImageUrlVar]: `url(${src})`,
      })}
      className={classnames(Styles.block)}
    >
      {credit && (
        <Box
          style={assignInlineVars({
            [Styles.BannerCreditVar]: `"credit: ${credit}"`,
          })}
          className={classnames('banner__credit', Styles.credit)}
        />
      )}
    </Box>
  );
}
