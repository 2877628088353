import type { PropsWithChildren, ReactNode } from 'react';
import classnames from 'classnames';

import { Box } from '@zenobius/ui-web-components-box';
import { Divider } from '@zenobius/ui-web-components-divider';

import * as Styles from './PageHeader.css';

export type PageHeaderProps = {
  className?: string;
  title: ReactNode;
  date?: string | null;
};

export const PageHeader = ({
  className,
  title,
  date,
  children,
}: PropsWithChildren<PageHeaderProps>) => {
  return (
    <Box
      gap="Normal"
      direction="column"
      className={classnames('page-header', Styles.block, className)}
      asChild
    >
      <header>
        <h2 className={classnames('page-header__title', Styles.title)}>
          {title}
        </h2>
        <Box
          gap="Normal"
          direction="column"
        >
          {date && (
            <small className={classnames('page-header__date', Styles.date)}>
              {date}
            </small>
          )}
          {!!children && (
            <>
              <Divider background="linkActive" />
              {children}
            </>
          )}
        </Box>
      </header>
    </Box>
  );
};
