import type { PropsWithChildren, ReactNode } from 'react';
import classnames from 'classnames';

import type { BoxProps } from '@zenobius/ui-web-components-box';
import { Box } from '@zenobius/ui-web-components-box';

import { PageHeader } from './PageHeader';
import * as Styles from './Page.css';
import { Banner } from './Banner';

export interface PageProps {
  className?: string;
  asideSlot?: ReactNode;
}

export const Page = ({ className, children }: PropsWithChildren<PageProps>) => {
  return (
    <Box
      direction="column"
      justifyContent="flex-start"
      flexGrow={1}
      className={classnames('page', Styles.page, className)}
      asChild
    >
      <article>{children}</article>
    </Box>
  );
};

Page.Header = PageHeader;
Page.Banner = Banner;
Page.Block = PageBlock;

function PageBlock(props: BoxProps) {
  return (
    <Box
      direction="column"
      flexGrow={1}
      justifyContent="flex-start"
      {...props}
      className={classnames('page__block', Styles.pageBlock, props.className)}
    />
  );
}
