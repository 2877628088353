import type { PropsWithChildren } from 'react';
import classNames from 'classnames';

import type { BoxProps } from '@zenobius/ui-web-components-box';
import { Box } from '@zenobius/ui-web-components-box';
import { extractProps } from '@zenobius/react-extract-props';

import type { DividerProps } from './Divider.css';
import { DividerRecipe } from './Divider.css';

export type UiWebComponentsDividerProps = PropsWithChildren<
  Omit<BoxProps, 'direction'> & DividerProps
>;

export function Divider(props: UiWebComponentsDividerProps) {
  const parsedProps = extractProps(props, ['direction']);

  return (
    <Box
      {...parsedProps.excluded}
      className={classNames(DividerRecipe(parsedProps.extracted))}
    />
  );
}
